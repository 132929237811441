
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background-color:#E9F8F9;
}

table, td, tr, th{
  text-align: center;
  border: 1px solid;
  /* border-collapse: collapse; */
  padding: 5px;
  /* border-radius: 200px; */
}
.sideMaintainer{ /* MAINTAINS CODE OF "lefthome" as well*/
  font-size:4rem; 
  color: white;
  padding: 2vmax;
  text-align: left;
  position: absolute;
  top: 45%;
  left: 35%;
  transform: translate(-50%, -50%);
}

.section{
  padding-top: 10vh;
  padding-bottom: 5vh;
  margin-left: 10vw;
  margin-right: 4vw;
}

.serviceBox{
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  background: white;
  padding: 0 1vw 5vh 1vw;
  margin: 1vw;
  border-radius: 1rem;
}

/*  BODY
    AND
    HOME
    PAGE
    STYLES
*/

.carouselContainer{
  position: relative;
  margin-top: 0vh;
  margin-bottom: 5vh; /* Apply only on small screens */
  display: flex;
  justify-content: center;
}


.under {
  -webkit-text-decoration:#537FE7 solid underline;
  text-decoration:  #537FE7 solid underline;
  -webkit-text-underline-position: under;
  text-underline-position: under;
}
.imgshadow {
  box-shadow: 8px 8px 10px #aaa;
  
}

.glass{
  background: rgba( 255, 255, 255, 0.25 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 4px );
-webkit-backdrop-filter: blur( 4px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

hr {
  border: none;
  border-top: 5px dotted #537FE7;
  opacity: 0.5;
}

.custom::before {
  content: "";
  position: absolute;
  left: 7%;
  bottom: 16px;
  width: 150px;
  height: 14px;
  transform: skew(-12deg) translateX(-50%);
  background: rgba(238,111,87,0.5);
  z-index: -1;
}

.left {
  position: absolute;
  top: 50%;
  left: 35%;
  transform: translate(-50%, -50%);
}
.left1 {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
}
.lefthome {
  position: absolute;
  top: 45%;
  left: 35%;
  transform: translate(-50%, -50%);
}




@media screen and (max-width: 600px) {
  .sideMaintainer{ /* MAINTAINS CODE OF "centered" as well*/
    font-size: 1rem;
    text-align: center;
    position: absolute; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .section{
    padding-top: 1vh;
    padding-bottom: 1vh;
  }
  .serviceBox{
    margin: 2vmax;
    margin-bottom: 2vh;
  }


  .smallKilled{
    display: none;
  }
}

@media screen and (aspect-ratio: 16/9) {
  body {
      background-color: lightgreen;
  }
}

@media screen and (max-width: 600px) and (aspect-ratio: 16/9) {
  /* Styles for screens with width up to 600px and 16:9 aspect ratio */
  body {
      background-color: lightcoral;
  }
}